import { css } from '@emotion/css';
import { Modal, Tabs } from 'antd';
import React from 'react';
import Toastify from "toastify-js";

const modalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 20,
        color: "#393939"
    },

    "& .link-wrapper": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F4F4F4',
        borderRadius: 4,
        padding: 10
    },

    "& .iframe-map": {
        height: 355,
        marginTop: 12,

        "& iframe": {
            height: "100%",
            width: "100%",
            borderColor: "transparent",
            borderRadius: 4
        }
    }
});


export const Share = ({ open, closefn, map }) => {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);

        const toastContent = document.createElement('div');
        toastContent.className = 'nmapsgl-toast-content';
        toastContent.innerText = 'Copied';

        Toastify({
            node: toastContent,
            duration: 5000,
            close: true,
            gravity: "bottom",
            position: "center",
            className: "nmapsgl-toast"
        }).showToast();
    };


    // Create url
    const url = window.location.href;
    const hashIndex = url.indexOf("#");
    const baseUrl = hashIndex !== -1 ? url.slice(0, hashIndex) : url;
    const hash = hashIndex !== -1 ? url.slice(hashIndex) : "";
    const shareUrl = `${baseUrl}${hash}`;
    const embededUrl = `${baseUrl}&embebed=true${hash}`;

    const linkHTML =
        <div className="link-wrapper">
            <div className="url">{shareUrl}</div>
            <div className="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onClick={() => { copyToClipboard(shareUrl); }}> </div>
        </div>

    const embedHTML =
        <>
            <div className="link-wrapper">
                <div className="url">{embededUrl}</div>
                <div className="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onClick={() => { copyToClipboard(`<iframe src="${embededUrl}">`); }}> </div>
            </div>
            <div className='iframe-map'>
                <iframe title="map" src={shareUrl}></iframe>
            </div>
        </>

    const items = [
        {
            key: 'link',
            label: 'Send a link',
            children: linkHTML,
        },
        // {
        //     key: 'embed',
        //     label: 'Embed map',
        //     children: embedHTML
        // }
    ];

    return (
        <Modal open={open} maskClosable={false} onCancel={() => { closefn() }} width={536} className={modalStyle} title="Share" footer={null}>
            <Tabs defaultActiveKey="link" items={items} tabBarStyle={{ width: 180 }} />
        </Modal>
    )
}