import * as nmapsgl from '@ndrive/nmaps-gl';
import '@ndrive/nmaps-gl/dist/nmaps-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintContent } from '../menu/components/print';
import Menu from '../menu/menu';
import './map.css';

export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const printRef = useRef();
  const hamburguerContainerRef = useRef(null);

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    nmapsgl.setApiUrl(process.env.REACT_APP_BASIC_URL)
    nmapsgl.setAccessToken(process.env.REACT_APP_ACCESS_TOKEN);

    map.current = new nmapsgl.Map({
      container: mapContainer.current,
      center: [5.93, 47.79],
      zoom: 4,
      hash: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
      layerOptions: {
        "nmapsgl_street_view": {
          mapillaryAccessToken: "MLY|3851069871664582|8e9c9f076cda1069d371524812123f7b"
        },
        "nmapsgl_opensky": {
          openSkyAuth: btoa("ndrive_devops:voxtun-nYjmi1-byfbaj")
        },
        "nmapsgl_pois": {
          initialEnabled: true,
        }
      }
    });

    map.current.addControl(new nmapsgl.AttributionControl({ compact: false }), "bottom-right");
    map.current.addControl(new nmapsgl.ScaleControl(), "bottom-left");

    map.current.addControl(new nmapsgl.StyleControl({ compact: false }), "bottom-right");

    map.current.addControl(new nmapsgl.RotateControl(), "bottom-right");

    map.current.addControl(new nmapsgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    }), 'bottom-right');

    map.current.addControl(new nmapsgl.NavigationControl({
      showCompass: false
    }), 'bottom-right');

    map.current.addControl(new nmapsgl.NavigationControl({
      showZoom: false
    }), 'bottom-right');

    map.current.addControl(new nmapsgl.LayersControl(), 'top-right');

    hamburguerContainerRef.current = document.createElement('div');
    map.current.addControl(new nmapsgl.DirectionControl({
      prependElement: hamburguerContainerRef.current,
      profileIds: ["Car", "Walking"]
    }), 'top-left');

    setMapInitialized(true);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => {
      if (!printRef.current) return;
      const printablemap = printRef.current.querySelector("#printable-map");
      printablemap.style["background-image"] = `url(${map.current.getCanvas().toDataURL("image/png")})`;
      printablemap.classList.remove("with-notes");
      const notes = printRef.current.querySelector("#print-notes");
      notes.style["display"] = "none";
      return printRef.current
    },
  });

  window.addEventListener('keydown', (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      handlePrint()
    }
  });


  return (
    <div className="map-wrap">
      <div id="mapContainer" ref={mapContainer} className="map" />
      {mapInitialized && <Menu map={map.current} printRef={printRef.current} hamburguerContainerRef={hamburguerContainerRef.current} />}
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} />
      </div>
    </div>
  );
}