// Menu.js
import { DirectionControl } from '@ndrive/nmaps-gl';
import '@ndrive/nmaps-gl/dist/nmaps-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { About } from './components/about';
import { Print } from './components/print';
import { Share } from './components/share';
import './menu.css';

const Menu = ({ map, printRef, hamburguerContainerRef }) => {
    const [menuInitialized, setMenuInitialized] = useState(false);
    const directionsControlRef = useRef(null);
    const menuContainerRef = useRef(null);

    const showMenu = () => {
        // Hide wrappers
        directionsControlRef.current._searchWrapper.classList.remove('show');
        directionsControlRef.current._searchWrapper.classList.add('hide');
        directionsControlRef.current._categoriesWrapper.classList.remove('show');
        directionsControlRef.current._historyWrapper.classList.remove('show');
        directionsControlRef.current._resultsWrapper.classList.remove('show');

        // Clear input
        const input = document.getElementById("search-input");
        input.value = "";
        directionsControlRef.current.updateInput(input.value);

        // Show menu
        menuContainerRef.current.classList.remove('hide');
    };

    const backMenu = () => {
        directionsControlRef.current._searchWrapper.classList.remove('hide');
        directionsControlRef.current._searchWrapper.classList.add('show');
        menuContainerRef.current.classList.add('hide');
    };

    useEffect(() => {
        // Initiate directions control reference
        directionsControlRef.current = map._controls.find(c => c instanceof DirectionControl);

        // Add menu wrapper to control group
        menuContainerRef.current = document.createElement('div');
        menuContainerRef.current.className = 'maplibregl-ctrl menu-wrapper hide';
        const ctrlGroupLeft = window.document.getElementsByClassName("maplibregl-ctrl-top-left")[0];
        ctrlGroupLeft.append(menuContainerRef.current);

        // Set control variable
        setMenuInitialized(true);
    }, []);

    return (
        <>
            {menuInitialized && createPortal(<HamburgerButton onClick={showMenu} />, hamburguerContainerRef)}
            {menuInitialized && createPortal(<MenuComponent onBack={backMenu} map={map} printRef={printRef} />, menuContainerRef.current)}
        </>
    );
}


const HamburgerButton = ({ onClick }) => (
    <div className="menu-btn-wrapper" onClick={onClick}>
        <div className="menu-button"></div>
    </div>
);

const MenuComponent = ({ onBack, map, printRef }) => {
    const [openAbout, setOpenAbout] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const [openShare, setOpenShare] = useState(false);

    return (
        <>
            <div className="menu-wrapper-header">
                <div className="back" onClick={onBack}></div>
                <div className="title">Menu</div>
            </div>
            <ul className="menu-wrapper-options">
                <li onClick={() => setOpenShare(true)}>
                    <div className="icon link"></div>
                    {/* TODO: Implement embeded : <div>Share or embed map</div> */}
                    <div>Share map</div>
                </li>
                <li onClick={() => setOpenPrint(true)}>
                    <div className="icon print"></div>
                    <div>Print</div>
                </li>
                <li
                    onClick={() =>
                        window.open('https://nmaps.zendesk.com/hc/en-us/requests/new?ticket_form_id=7526151664530', '_blank')
                    }
                >
                    <div className="icon feedback"></div>
                    <div>Feedback</div>
                </li>
                <li onClick={() => window.open('https://nmaps.zendesk.com/hc/en-us/sections/4411798208274-FAQ', '_blank')}>
                    <div className="icon help"></div>
                    <div>Help</div>
                </li>
                <li onClick={() => setOpenAbout(true)}>
                    <div className="icon about"></div>
                    <div>About</div>
                </li>
            </ul>
            <div className="menu-wrapper-footer"></div>
            <About open={openAbout} closefn={() => setOpenAbout(false)} />
            <Print open={openPrint} closefn={() => setOpenPrint(false)} map={map} printRef={printRef} />
            <Share open={openShare} closefn={() => setOpenShare(false)} map={map} />
        </>
    );
}

export default Menu;
