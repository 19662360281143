import { browserTracingIntegration, init as initSentry, replayIntegration } from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

initSentry({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  environment: process.env.ENV,
  enabled: process.env.SENTRY_ENABLED?.toLowerCase() == "true"
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);