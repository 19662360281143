// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-wrap {
    position: relative;
    width: 100%;
    height: 100vh
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}


/*************************/
/*** print             ***/
/*************************/

@media print {

    .maplibregl-ctrl,
    .ant-modal-root {
        display: none;
    }

    #mapContainer {
        width: 98%;
        height: 98%;
        padding: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/map/map.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX;AACJ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;;AAGA,0BAA0B;AAC1B,0BAA0B;AAC1B,0BAA0B;;AAE1B;;IAEI;;QAEI,aAAa;IACjB;;IAEA;QACI,UAAU;QACV,WAAW;QACX,UAAU;IACd;AACJ","sourcesContent":[".map-wrap {\n    position: relative;\n    width: 100%;\n    height: 100vh\n}\n\n.map {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n\n/*************************/\n/*** print             ***/\n/*************************/\n\n@media print {\n\n    .maplibregl-ctrl,\n    .ant-modal-root {\n        display: none;\n    }\n\n    #mapContainer {\n        width: 98%;\n        height: 98%;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
